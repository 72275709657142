import {Component, inject} from '@angular/core';
import {LibLoadingIconComponent} from "../ui/lib-loading-icon/lib-loading-icon.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    LibLoadingIconComponent
  ],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  protected isLoading = false;
  private loadingService = inject(LoadingService);

  constructor() {
    this.loadingService.isLoading$.pipe(takeUntilDestroyed()).subscribe((isDisplayed)=>{
      this.isLoading = isDisplayed;
    });
  }
}
