import {Routes} from '@angular/router';
import {AuthGuardService} from "./services/auth-guard.service";
import {NotLoggedInGuardService} from "./services/not-loggedin-guard.service";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-dashboard/page-dashboard.component').then(m => m.PageDashboardComponent),
  },
  {
    path: "auth",
    canActivate: [NotLoggedInGuardService],
    children: [
      {
        path: 'login',
        loadComponent: () => import('./pages/authentication/page-login/page-login.component').then(m => m.PageLoginComponent),
      },
      {
        path: 'create-account',
        loadComponent: () => import('./pages/authentication/page-create-account/page-create-account.component').then(m => m.PageCreateAccountComponent),
      },
      {
        path: 'verify',
        loadComponent: () => import('./pages/authentication/page-verify/page-verify.component').then(m => m.PageVerifyComponent),
      },
      {
        path: 'username',
        loadComponent: () => import('./pages/authentication/page-username/page-username.component').then(m => m.PageUsernameComponent),
      },
    ]
  },
  {
    path: "setup",
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/setup/page-create-band/page-create-band.component').then(m => m.PageCreateBandComponent),
      },
      {
        path: 'logo',
        loadComponent: () => import('./pages/setup/page-logo/page-logo.component').then(m => m.PageLogoComponent),
      },
      {
        path: 'uniform',
        loadComponent: () => import('./pages/setup/page-uniform/page-uniform.component').then(m => m.PageUniformComponent),
      },
      {
        path: 'uniform/part/:partId',
        loadComponent: () => import('./pages/contents/page-uniform-part/page-uniform-part.component').then(m => m.PageUniformPartComponent),
      },
    ]
  },
  {
    path: 'my-band',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/my-band/page-my-band/page-my-band.component').then(m => m.PageMyBandComponent),
      },
      {
        path: 'instruments',
        loadComponent: () => import('./pages/my-band/page-band-instruments/page-band-instruments.component').then(m => m.PageBandInstrumentsComponent),
      },
      {
        path: 'logo',
        loadComponent: () => import('./pages/my-band/page-band-logo/page-band-logo.component').then(m => m.PageBandLogoComponent),
      },
      {
        path: 'uniform',
        loadComponent: () => import('./pages/my-band/page-band-uniform/page-band-uniform.component').then(m => m.PageBandUniformComponent),
      },
      {
        path: 'details',
        loadComponent: () => import('./pages/my-band/page-band-details/page-band-details.component').then(m => m.PageBandDetailsComponent),
      },
      {
        path: 'players',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/my-band/page-band-players/page-players.component').then(m => m.PagePlayersComponent),
          }
        ]
      }
    ]
  },
  {
    path: 'events',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/events/page-events/page-events.component').then(m => m.PageEventsComponent),
      }
    ]
  },
  {
    path: 'ranking',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-ranking/page-ranking.component').then(m => m.PageRankingComponent),
  },
  {
    path: 'more',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/more/page-more/page-more.component').then(m => m.PageMoreComponent),
      },
      {
        path: 'feedback',
        loadComponent: () => import('./pages/more/page-feedback/page-feedback.component').then(m => m.PageFeedbackComponent),
      },
      {
        path: 'preferences',
        loadComponent: () => import('./pages/more/page-preferences/page-preferences.component').then(m => m.PagePreferencesComponent),
      },
      {
        path: 'profile',
        children: [
          {
            path: "",
            loadComponent: () => import('./pages/more/page-profile/page-profile.component').then(m => m.PageProfileComponent),
          },
          {
            path: "change-username",
            loadComponent: () => import('./pages/more/page-change-username/page-change-username.component').then(m => m.PageChangeUsernameComponent),
          },
          {
            path: "change-email",
            loadComponent: () => import('./pages/more/page-change-email/page-change-email.component').then(m => m.PageChangeEmailComponent),
          },
          {
            path: "change-password",
            loadComponent: () => import('./pages/more/page-change-password/page-change-password.component').then(m => m.PageChangePasswordComponent),
          }
        ]
      },
    ]
  },
];
