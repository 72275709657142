import {Component, inject, NgZone} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient} from "@angular/common/http";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {environment} from "../environments/environment";
import {CustomMissingTranslationHandler} from "./classes/CustomMissingTranslationHandler.class";
import {MainMenuComponent} from "./components/layout/main-menu/main-menu.component";
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {AlertComponent} from "./components/ui/alert/alert.component";
import {Store} from "@ngxs/store";
import {UserState} from "../shared/user-state/user.state";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {
  BandmanagerRestApiAuthenticationService,
  BandmanagerRestApiBand,
  BandmanagerRestApiBandStatsDto,
  BandmanagerRestApiBankaccount,
  BandmanagerRestApiMyBandService,
  BandmanagerRestApiMyUniformService,
  BandmanagerRestApiProfileDto,
  BandmanagerRestApiUniform
} from "@digitale-menschen/bandmanager-rest-api";
import {lastValueFrom, take} from "rxjs";
import {UserStateActions} from "../shared/user-state/user-state.actions";
import {BandStateActions} from "../shared/band-state/band-state.actions";
import {AppHeaderComponent} from "./components/layout/header/app-header/app-header.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {LoadingService} from "./services/loading.service";
import {DeviceService} from "./services/device.service";
import {OnboardingService} from "./services/onboarding.service";
import {ModalService} from "./services/modal/modal.service";
import {Capacitor} from "@capacitor/core";
import {initializeApp} from "firebase/app";
import {viewTransitionMain} from "./angular-animations";
import {AlertService} from "./services/alert.service";

/**
 * Create a custom translation loader
 * @param httpClient
 * @returns {TranslateHttpLoader}
 */
export function createTranslateLoader(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, environment.translation_path, '.json');
}

/**
 * Factory for CustomMissingTranslationHandler
 *
 * @returns {CustomMissingTranslationHandler}
 */
export function createCustomMissingTranslationHandler(): CustomMissingTranslationHandler {
  return new CustomMissingTranslationHandler();
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainMenuComponent, AlertComponent, AppHeaderComponent,
    LoadingComponent, TranslateModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    viewTransitionMain,
  ]
})
export class AppComponent {
  title = 'bandmanager-app';
  protected isLoggedIn = false;
  private translateService = inject(TranslateService);
  private zone = inject(NgZone);
  private router = inject(Router);
  private apiService = inject(BandmanagerRestApiMyBandService);
  private apiAuthenticateService = inject(BandmanagerRestApiAuthenticationService);
  private apiUniformService = inject(BandmanagerRestApiMyUniformService);
  private deviceService = inject(DeviceService);
  private store = inject(Store);
  private loadingService = inject(LoadingService);
  private onboardingService = inject(OnboardingService);
  private modalService = inject(ModalService);
  private alertService = inject(AlertService);
  private userProfile: BandmanagerRestApiProfileDto | null = null;

  constructor() {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    registerLocaleData(localeDe);

    this.initListeners();

    // listen to State isLogin, and load everything
    this.store.select(UserState.isLoggedIn).pipe(takeUntilDestroyed()).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.loadAndCacheData();
        this.deviceService.updateDevice();
      }
    });

    this.initializeFirebase();
  }

  protected initListeners(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".app").pop();
        if (slug) {
          this.router.navigateByUrl(slug).then(() => {
          });
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }).then(() => {
    });
  }

  public getAnimationState(outlet: RouterOutlet) {
    if (outlet && outlet.activatedRouteData) {
      return outlet.activatedRouteData.animationPageName
    } else {
      return undefined;
    }
  }

  private async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase_config);
  }

  /**
   * Load data from Api and save it in State
   * @private
   */
  private loadAndCacheData(): void {
    const profilePromise = lastValueFrom(this.apiAuthenticateService.authenticationControllerProfile());
    const myBandPromise = lastValueFrom(this.apiService.bandControllerGetMyBand());
    const bankAccountPromise = lastValueFrom(this.apiService.bankaccountControllerGetMyBand());
    const statsPromise = lastValueFrom(this.apiService.bandControllerGetMyBandStats());
    const uniformPromise = lastValueFrom(this.apiUniformService.uniformControllerGetMyUniform());

    const promiseArr = [profilePromise, myBandPromise, bankAccountPromise, statsPromise, uniformPromise];
    this.loadingService.display();
    Promise.all(promiseArr).then((results) => {
      // profile
      this.store.dispatch(new UserStateActions.SetProfile(results[0] as BandmanagerRestApiProfileDto));
      // myBand
      this.store.dispatch(new BandStateActions.SetBand(results[1] as BandmanagerRestApiBand));
      // bankAccount
      this.store.dispatch(new BandStateActions.SetBankAccount(results[2] as BandmanagerRestApiBankaccount));
      // stats
      this.store.dispatch(new BandStateActions.SetStats(results[3] as BandmanagerRestApiBandStatsDto));
      // uniform
      this.store.dispatch(new BandStateActions.SetUniform(results[4] as BandmanagerRestApiUniform));

      this.userProfile = results[0] as BandmanagerRestApiProfileDto;
      this.checkAccountLock();
    }).finally(() => {
      this.loadingService.hide();
    });
  }

  private checkAccountLock(): void {
    let targetUrl = '';
    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation?.finalUrl?.root?.children?.primary?.segments) {
      targetUrl = currentNavigation.finalUrl.root.children.primary.segments.join('/');
    }

    if (targetUrl && targetUrl.indexOf("auth/verify") > -1) {
      return;
    }

    const profileIsOk = this.onboardingService.checkProfileForEmailVerifyIsOk(this.userProfile as BandmanagerRestApiProfileDto);
    if (!profileIsOk) {
      this.modalService.open(undefined, "modal-profile-blocked", {}).then(() => {
      });
    } else {
      const modalTargetUrl = window.location.href;
      if (modalTargetUrl.indexOf("modal-router:modal-profile-blocked") > -1) {
        this.modalService.close();
        this.router.navigateByUrl("/");
      } else if (!this.userProfile?.user.isConfirmed) {
        this.displayEmailConfirmWarning();
      }
    }
  }

  private displayEmailConfirmWarning(): void {
    this.translateService.get(['onboarding.verify-email-warning', 'authentication.alert.resend-email']).pipe(take(1)).subscribe((texts) => {
      this.alertService.display(
        "warning",
        texts['onboarding.verify-email-warning'],
        this.resendEmail.bind(this),
        texts['authentication.alert.resend-email']);
    });
  }

  private resendEmail(): void {
    if (this.userProfile && this.userProfile.user.email) {
      this.onboardingService.resendEmail(<string>this.userProfile.user.email);
    }
  }
}
