import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { HapticTapDirective } from '../../../directives/haptic-tap.directive';
import { Store } from '@ngxs/store';
import { UserState } from '../../../../shared/user-state/user.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-main-menu',
  imports: [
    CommonModule,
    TranslateModule,
    RouterLinkActive,
    RouterLink,
    HapticTapDirective,
  ],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
})
export class MainMenuComponent {
  protected isLoggedIn: boolean = false;
  private store = inject(Store);

  constructor() {
    this.store.select(UserState.isLoggedIn).pipe(takeUntilDestroyed()).subscribe((isLoggedIn) => {
      console.log('Main menu: isLoggedIn', isLoggedIn);
      this.isLoggedIn = isLoggedIn;
    });
  }
}
