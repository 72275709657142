import {inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import dayjs from 'dayjs';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from './loading.service';
import {
  BandmanagerRestApiAuthenticationService,
  BandmanagerRestApiBandStatsDto,
  BandmanagerRestApiBandWithRanksDto,
  BandmanagerRestApiBankaccount,
  BandmanagerRestApiEventParticipation,
  BandmanagerRestApiMyBandService,
  BandmanagerRestApiMyEventsService,
  BandmanagerRestApiMyUniformService,
  BandmanagerRestApiProfileDto,
  BandmanagerRestApiUniform,
} from '@digitale-menschen/bandmanager-rest-api';
import {UserStateActions} from '../../shared/user-state/user-state.actions';
import {BandStateActions} from '../../shared/band-state/band-state.actions';
import {EventsStateActions} from '../../shared/events-state/events-state.actions';
import {BandroomService} from './bandroom.service';
import {Store} from '@ngxs/store';
import {ApiSessionService} from './api-session.service';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  // 30 min
  private dynBackgroundTimerInterval = 1800000;
  private dynBackgroundTimerRef: any = null;
  private dynBackgroundCssDay = 'var(--color-dyn-background-day)';
  private dynBackgroundCssNight = 'var(--color-dyn-background-night)';
  private dynBackgroundCssDefault = '--color-dyn-background';
  private loadingService = inject(LoadingService);
  private apiAuthenticateService = inject(BandmanagerRestApiAuthenticationService);
  private apiService = inject(BandmanagerRestApiMyBandService);
  private apiUniformService = inject(BandmanagerRestApiMyUniformService);
  private apiEventService = inject(BandmanagerRestApiMyEventsService);
  private bandroomService = inject(BandroomService);
  private store = inject(Store);
  private apiSessionService = inject(ApiSessionService);

  public startDynamicBackground(): void {
    this.updateDynamicBackground();
    if (!this.dynBackgroundTimerRef) {
      this.dynBackgroundTimerRef = setInterval(() => {
        this.updateDynamicBackground();
      }, this.dynBackgroundTimerInterval);
    }
  }

  /**
   * Load data from Api and save it in State
   * @private
   */
  public async loadAndCacheData(): Promise<void> {
    // verify the logged-in
    try {
      console.log("init, refreshing token");
      const refreshSuccess = await this.apiSessionService.refreshToken();
      if (!refreshSuccess) {
        this.loadingService.hide();
        console.error("refresh token error, stopping loading");
        alert("error by refreshing token in init - LoadAndCacheData");
        // api-session.service is redirecting to Login
        return;
      }
    } catch (error) {
      console.error("refresh token error, stopping loading", error);
    }

    // then load everything
    const profilePromise = lastValueFrom(this.apiAuthenticateService.authenticationControllerProfile());
    const myBandPromise = lastValueFrom(this.apiService.bandControllerGetMyBand());
    const bankAccountPromise = lastValueFrom(this.apiService.bankaccountControllerGetMyBankaccount());
    const statsPromise = lastValueFrom(this.apiService.bandControllerGetMyBandStats());
    const uniformPromise = lastValueFrom(this.apiUniformService.uniformControllerGetMyUniform());
    const eventParticipations = lastValueFrom(this.apiEventService.eventParticipationsControllerGetMyEventParticipations());
    const promiseArr = [
      profilePromise,
      myBandPromise,
      bankAccountPromise,
      statsPromise,
      uniformPromise,
      eventParticipations,
    ];
    // this.loadingService.display();
    const results = await Promise.all(promiseArr);
    // profile
    await lastValueFrom(this.store.dispatch(new UserStateActions.SetProfile(results[0] as BandmanagerRestApiProfileDto)));
    // myBand
    await lastValueFrom(this.store.dispatch(new BandStateActions.SetBand(results[1] as BandmanagerRestApiBandWithRanksDto)));
    // bankAccount
    this.store.dispatch(new BandStateActions.SetBankAccount(results[2] as BandmanagerRestApiBankaccount));
    // stats
    this.store.dispatch(new BandStateActions.SetStats(results[3] as BandmanagerRestApiBandStatsDto));
    // uniform
    this.store.dispatch(new BandStateActions.SetUniform(results[4] as BandmanagerRestApiUniform));
    // participations
    this.store.dispatch(new EventsStateActions.setEventsParticipations(results[5] as BandmanagerRestApiEventParticipation[]));

    await this.bandroomService.loadUpgrades(false);
    this.loadingService.hide();
  }

  private updateDynamicBackground(): void {
    const isNight = dayjs().isAfter(environment.nightStartHour, 'hour') && dayjs().isBefore(environment.dayStartHour, 'hour');
    document.documentElement.style.setProperty(this.dynBackgroundCssDefault,
      isNight ? this.dynBackgroundCssNight : this.dynBackgroundCssDay);
  }
}
