import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertService } from '../../../services/alert.service';
import { AlertInterface } from '../../../models/alert.interface';

@Component({
  selector: 'app-alert',
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  protected alertData: AlertInterface | null = null;
  protected goingToClose = false;
  private alertService = inject(AlertService);
  private timeToClose = 10000;
  private timer: any | null = null;

  constructor() {
    this.alertService.isDisplayed$.pipe(takeUntilDestroyed()).subscribe((alertData) => {
      if (alertData) {
        this.goingToClose = false;
        this.alertData = alertData;
        if (!this.timer) {
          this.timer = setTimeout(() => {
            this.close();
          }, this.timeToClose);
        }
      } else {
        this.goingToClose = true;
        // defined in alert.component.scss
        const animationTime = 350;
        setTimeout(() => {
          this.alertData = null;
        }, animationTime);
      }
    });
  }

  protected close(): void {
    this.alertService.hide();
    clearTimeout(this.timer);
    this.timer = null;
  }
}
