import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {Component, effect, inject, NgZone, Signal, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Device} from '@capacitor/device';
import {
  BandmanagerRestApiBand,
  BandmanagerRestApiCreateUniformDto,
  BandmanagerRestApiProfileDto,
  BandmanagerRestApiUniform,
} from '@digitale-menschen/bandmanager-rest-api';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {initializeApp} from 'firebase/app';
import {filter, take} from 'rxjs';
import {environment} from '../environments/environment';
import {BandState} from '../shared/band-state/band.state';
import {OnboardingStateModel} from '../shared/onboarding-state/onboarding-state.models';
import {OnboardingState} from '../shared/onboarding-state/onboarding.state';
import {UserStateActions} from '../shared/user-state/user-state.actions';
import {UserState} from '../shared/user-state/user.state';
import {viewTransitionMain} from './angular-animations';
import {AppHeaderComponent} from './components/layout/header/app-header/app-header.component';
import {MainMenuComponent} from './components/layout/main-menu/main-menu.component';
import {LoadingComponent} from './components/loading/loading.component';
import {AlertComponent} from './components/ui/alert/alert.component';
import {LibDialogComponent} from './components/ui/lib-dialog/lib-dialog.component';
import {AdsService} from './services/ads.service';
import {AlertService} from './services/alert.service';
import {AnalyticsService} from './services/analytics.service';
import {DeviceService} from './services/device.service';
import {InitializationService} from './services/initialization.service';
import {ModalService} from './services/modal/modal.service';
import {OnboardingService} from './services/onboarding.service';
import {RehearsalService} from './services/rehearsal.service';
import SetPreferencesLanguage = UserStateActions.SetPreferencesLanguage;

@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    MainMenuComponent,
    AlertComponent,
    AppHeaderComponent,
    LoadingComponent,
    TranslateModule,
    LibDialogComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    viewTransitionMain,
  ],
})
export class AppComponent {
  @ViewChild(RouterOutlet) outlet!: RouterOutlet;
  protected showAnnoyedAdsPopup = false;
  private translateService = inject(TranslateService);
  private zone = inject(NgZone);
  private router = inject(Router);
  private deviceService = inject(DeviceService);
  private store = inject(Store);
  private onboardingService = inject(OnboardingService);
  private modalService = inject(ModalService);
  private alertService = inject(AlertService);
  private userProfile: BandmanagerRestApiProfileDto | null = null;
  private adsService = inject(AdsService);
  private rehearsalService = inject(RehearsalService);
  private initService = inject(InitializationService);
  private analyticsService = inject(AnalyticsService);

  constructor() {
    console.log("inAppComponent");

    // init 1
    try {
      this.setupI18n().catch();
      this.initStateSelectors();
      this.initListeners();
    } catch (error) {
      console.log("error by Init 1", error);
    }

    // init 2
    try {
      // listen to State isLogin, and load everything
      this.store.select(UserState.isLoggedIn).pipe(takeUntilDestroyed()).subscribe((isLoggedIn) => {
        console.log("isLoggedIn", isLoggedIn);
        // if (isLoggedIn) {
        this.doInit();
        // }
      });
    } catch (error) {
      console.log("error by Init 2 - store UserState, launching doInit manually", error);
    }

    // init 3
    try {
      this.initService.startDynamicBackground();
      this.initializeFirebase().then(() => {
        this.deviceService.initPushMessaging();
      });
    } catch (error) {
      console.log("error by Init 3", error);
    }
  }

  public getAnimationState(outlet: RouterOutlet) {
    if (outlet && outlet.activatedRouteData) {
      return outlet.activatedRouteData.animationPageName;
    } else {
      return undefined;
    }
  }

  protected initListeners(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.app').pop();
        if (slug) {
          this.router.navigateByUrl(slug).then(() => {
          });
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }).then(() => {
    });

    // TODO ATTENTION
    /*
    BANDMANAGER_OAUTH_REDIRECT_SUCCESS="https://localhost:4200/auth/oauth/success"
BANDMANAGER_OAUTH_REDIRECT_ERROR="https://localhost:4200/auth/oauth/error"
     */

    this.router.events.pipe(takeUntilDestroyed(), filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((event) => {
      if (this.outlet && this.outlet.activatedRouteData && this.outlet.activatedRouteData.routeName && !this.outlet.activatedRouteData.routeHasTabs) {
        // if route has tabs, the setCurrentScreen will be called by Tabs for the right activated tab
        this.analyticsService.setCurrentScreen(this.outlet.activatedRouteData.routeName);
      }
    });
  }

  protected goToAdsPage(): void {
    this.modalService.close();
    this.router.navigateByUrl('/more/ads').then();
  }

  private async setupI18n() {
    const availableLanguages = [
      'en',
      'de',
    ];
    let defaultLanguage = availableLanguages[0];
    try {
      const info = await Device.getLanguageCode();
      const deviceLang = info.value ? info.value.split('-')[0] : 'en'; // Extract base language (e.g., 'de' from 'de-DE')
      defaultLanguage = availableLanguages.includes(deviceLang) ? deviceLang : 'en';
    } catch (deviceLanguageException) {
      console.error('deviceLanguageException', deviceLanguageException);
    }
    this.translateService.setDefaultLang(defaultLanguage);
    const currentStateLanguage = this.store.selectSnapshot(UserState.preferencesLanguage);
    if (!currentStateLanguage) {
      // This will trigger the subscription within 'initStateSelectors()' which will execute 'translationService.use(...)'
      this.store.dispatch(new SetPreferencesLanguage(defaultLanguage));
    }
    registerLocaleData(localeDe);
  }

  private initStateSelectors(): void {
    const $onboardingStateData: Signal<OnboardingStateModel> = this.store.selectSignal(OnboardingState.onboardingData);
    effect(() => {
      if ($onboardingStateData().bandUniform) {
        this.setUniformColorsInCss(<BandmanagerRestApiCreateUniformDto>$onboardingStateData().bandUniform);
      }
    });

    const $bandStateUniform = this.store.selectSignal(BandState.uniform);
    effect(() => {
      if ($bandStateUniform()) {
        this.setUniformColorsInCss(<BandmanagerRestApiCreateUniformDto>$bandStateUniform());
      }
    });

    this.store.select(UserState.user).pipe(takeUntilDestroyed()).subscribe((user) => {
      this.userProfile = user?.profile || null;
    });

    this.store.select(BandState.band).pipe(takeUntilDestroyed()).subscribe((band) => {
      if (band) {
        this.setBandroomColors(band);
      }
    });

    // get UserState AdsViewed, if 5 or more, display popup and reset
    this.store.select(UserState.numberOfAdsViewed).pipe(takeUntilDestroyed()).subscribe((result) => {
      if (result >= environment.adsNumberBeforePopup) {
        this.showAnnoyedAdsPopup = true;
        this.store.dispatch(new UserStateActions.ResetNumberOfAds());
      }
    });

    this.store.select(UserState.preferencesLanguage).pipe(takeUntilDestroyed()).subscribe((language) => {
      this.translateService.use(language);
    });
  }

  private async doInit(): Promise<void> {
    await this.initService.loadAndCacheData();
    await this.deviceService.updateDevice();
    await this.adsService.initialize(!environment.production);
    this.checkAccountLock();
    this.rehearsalService.startRehearsalStatusUpdateInterval();
    await this.analyticsService.initTracking();
  }

  private async initializeFirebase(): Promise<void> {
    initializeApp(environment.firebase_config);
  }

  private setUniformColorsInCss(uniform: BandmanagerRestApiUniform | BandmanagerRestApiCreateUniformDto): void {
    if (uniform.colorPrimary) {
      document.documentElement.style.setProperty('--uniform-primary-color', uniform.colorPrimary);
    }
    if (uniform.colorSecondary) {
      document.documentElement.style.setProperty('--uniform-secondary-color', uniform.colorSecondary);
    }
  }

  private setBandroomColors(band: BandmanagerRestApiBand): void {
    document.documentElement.style.setProperty('--bandroom-floor-color', band.bandroomFloorColor || environment.bandroomFloorColorDefault);
    document.documentElement.style.setProperty('--bandroom-walls-color', band.bandroomWallsColor || environment.bandroomWallsColorDefault);
  }

  private getTargetUrl(): string {
    let targetUrl = '';
    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation?.finalUrl?.root?.children?.primary?.segments) {
      targetUrl = currentNavigation.finalUrl.root.children.primary.segments.join('/');
    }
    return targetUrl;
  }

  private checkAccountLock(): void {
    const targetUrl = this.getTargetUrl();
    if (targetUrl && targetUrl.indexOf('auth/verify') > -1) {
      return;
    }

    const profileIsOk = this.onboardingService.checkProfileForEmailVerifyIsOk(this.userProfile as BandmanagerRestApiProfileDto);
    if (!profileIsOk) {
      this.modalService.open(undefined, 'modal-profile-blocked', {}).then(() => {
      });
    } else {
      const modalTargetUrl = window.location.href;
      if (modalTargetUrl.indexOf('modal-router:modal-profile-blocked') > -1) {
        this.modalService.close();
        this.router.navigateByUrl('/').then();
      } else if (!this.userProfile?.user.isConfirmed) {
        this.displayEmailConfirmWarning();
      }
    }
  }

  private displayEmailConfirmWarning(): void {
    this.translateService.get([
      'onboarding.verify-email-warning',
      'authentication.alert.resend-email',
    ]).pipe(take(1)).subscribe((texts) => {
      this.alertService.display(
        'warning',
        texts['onboarding.verify-email-warning'],
        this.resendEmail.bind(this),
        texts['authentication.alert.resend-email']);
    });
  }

  private resendEmail(): void {
    if (this.userProfile && this.userProfile.user.email) {
      this.onboardingService.resendEmail(<string>this.userProfile.user.email);
    }
  }
}
