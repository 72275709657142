import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BandmanagerRestApiAuthenticationService } from '@digitale-menschen/bandmanager-rest-api';
import { Store } from '@ngxs/store';
import { UserStateActions } from '../../shared/user-state/user-state.actions';
import { AlertService } from './alert.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private alertService = inject(AlertService);
  private store = inject(Store);
  private bandmanagerRestApiAuthenticationService = inject(BandmanagerRestApiAuthenticationService);


  /**
   * Intercepts every HTTP request. It will turn on 'withCredentials' so that cookies are being sent to the API.
   * If a http 401 exception occurs, it will attempt to get new access and refresh token.
   * If this fails, it will update the UserState which can be used in the frontend to e.g. re-route, clear state etc.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          this.alertService.display('error', error.message);
          throw error;
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      /**
       * Get new access_token and refresh_token based on the current refresh_token.
       */
      return this.bandmanagerRestApiAuthenticationService.authenticationControllerRefreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return next.handle(request);
        }),
        catchError(error => {
          // logout the user if refreshToken is not possible
          this.store.dispatch(new UserStateActions.SetIsLoggedIn(false));
          throw error;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
