import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {AlertService} from "../../../services/alert.service";

export interface AlertInterface {
  type: "error" | "warning" | "info" | "success";
  text: string;
}

@Component({
  selector: 'lib-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  protected enableType: AlertInterface | null = null;
  private alertService = inject(AlertService);

  constructor() {
    this.alertService.isDisplayed$.pipe(takeUntilDestroyed()).subscribe((displayType)=>{
      this.enableType = displayType;
    });
  }

  protected close(): void {
    this.alertService.hide();
  }
}
