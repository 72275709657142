<app-header></app-header>
<div [@viewTransitionMain]="getAnimationState(outlet)" class="app-router-container">
  <router-outlet #outlet='outlet'></router-outlet>
</div>
<app-main-menu></app-main-menu>
<app-loading></app-loading>
<app-alert></app-alert>
<!-- remove ads - popup -->
<app-lib-dialog
  [titleText]="'more.ads.annoyed.title' | translate"
  [hasCloseButton]="false"
  [hasOkCancelButton]="true"
  [okText]="'more.ads.annoyed.submit' | translate"
  [cancelText]="'more.ads.annoyed.cancel' | translate"
  [show]="showAnnoyedAdsPopup"
  (okClicked)="goToAdsPage()"
  (cancelClicked)="showAnnoyedAdsPopup = false"
>{{ "more.ads.annoyed.text" | translate }}
</app-lib-dialog>
