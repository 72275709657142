import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserState } from '../../shared/user-state/user.state';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuardService implements CanActivate {
  private router = inject(Router);
  private store = inject(Store);

  canActivate(): boolean {
    const userIsLoggedIn = this.store.selectSnapshot(UserState.isLoggedIn);
    // if user is already loggedin, we go directly to dashboard
    if (userIsLoggedIn) {
      this.router.navigate(['/']).then();
      return false;
    }
    return true;
  }
}
