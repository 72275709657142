import {Component, inject, NgZone} from '@angular/core';
import {NavigationStart, Router, RouterOutlet} from '@angular/router';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {environment} from "../environments/environment";
import {CustomMissingTranslationHandler} from "./classes/CustomMissingTranslationHandler.class";
import {MainMenuComponent} from "./components/layout/main-menu/main-menu.component";
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {AlertComponent} from "./components/ui/lib-alert/alert.component";
import {LibLoadingIconComponent} from "./components/ui/lib-loading-icon/lib-loading-icon.component";
import {Store} from "@ngxs/store";
import {UserState} from "../shared/user-state/user.state";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {
  BandmanagerRestApiAuthenticationService,
  BandmanagerRestApiBand, BandmanagerRestApiBandStatsDto, BandmanagerRestApiBankaccount,
  BandmanagerRestApiMyBandService, BandmanagerRestApiProfileDto
} from "@digitale-menschen/bandmanager-rest-api";
import {lastValueFrom} from "rxjs";
import {UserStateActions} from "../shared/user-state/user-state.actions";
import {BandStateActions} from "../shared/band-state/band-state.actions";
import {AppHeaderComponent} from "./components/layout/header/app-header/app-header.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {LoadingService} from "./services/loading.service";

/**
 * Create a custom translation loader
 * @param httpClient
 * @returns {TranslateHttpLoader}
 */
export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.translation_path, '.json');
}

/**
 * Factory for CustomMissingTranslationHandler
 *
 * @returns {CustomMissingTranslationHandler}
 */
export function createCustomMissingTranslationHandler() {
  return new CustomMissingTranslationHandler();
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainMenuComponent, AlertComponent, LibLoadingIconComponent, AppHeaderComponent, LoadingComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'bandmanager-app';
  protected isLoggedIn = false;
  private translateService = inject(TranslateService);
  private zone = inject(NgZone);
  private router = inject(Router);
  private apiService = inject(BandmanagerRestApiMyBandService);
  private apiAuthenticateService = inject(BandmanagerRestApiAuthenticationService);
  private store = inject(Store);
  private loadingService = inject(LoadingService);

  constructor() {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    registerLocaleData(localeDe);

    this.initListeners();

    // listen to State isLogin, and load everything
    this.store.select(UserState.isLoggedIn).pipe(takeUntilDestroyed()).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.loadAndCacheData();
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // console.log(event.url)
      }
    })
  }

  protected initListeners(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".app").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  /**
   * Load data from Api and save it in State
   * @private
   */
  private loadAndCacheData(): void {
    const profilePromise = lastValueFrom(this.apiAuthenticateService.authenticationControllerProfile());
    const myBandPromise = lastValueFrom(this.apiService.bandControllerGetMyBand());
    const bankAccountPromise = lastValueFrom(this.apiService.bankaccountControllerGetMyBand());
    const statsPromise = lastValueFrom(this.apiService.bandControllerGetMyBandStats());
    const promiseArr = [profilePromise, myBandPromise, bankAccountPromise, statsPromise];
    this.loadingService.display();
    Promise.all(promiseArr).then((results) => {
      // profile
      this.store.dispatch(new UserStateActions.SetProfile(results[0] as BandmanagerRestApiProfileDto));
      // myBand
      this.store.dispatch(new BandStateActions.SetBand(results[1] as BandmanagerRestApiBand));
      // bankAccount
      this.store.dispatch(new BandStateActions.SetBankAccount(results[2] as BandmanagerRestApiBankaccount));
      // stats
      this.store.dispatch(new BandStateActions.SetStats(results[3] as BandmanagerRestApiBandStatsDto));
    }).finally(() => {
      this.loadingService.hide();
    });
  }
}
